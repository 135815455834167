import { createGlobalStyle } from 'styled-components'
// eslint-disable-next-line import/no-unresolved
import { PancakeTheme } from '@pancakeswap/uikit/dist/theme'

declare module 'styled-components' {
  /* eslint-disable @typescript-eslint/no-empty-interface */
  export interface DefaultTheme extends PancakeTheme {}
}

const GlobalStyle = createGlobalStyle`
  * {
    font-family: 'Kanit', sans-serif;
  }
  body {
    background-color: #1c0e13;

    img {
      height: auto;
      max-width: 100%;
    }
  }

  .fa-beat {
    animation:fa-beat 5s ease infinite;
  }
  @keyframes fa-beat {
    0% {
      transform:scale(1);
    }
    5% {
      transform:scale(1.25);
    }
    20% {
      transform:scale(1);
    }
    30% {
      transform:scale(1);
    }
    35% {
      transform:scale(1.25);
    }
    50% {
      transform:scale(1);
    }
    55% {
      transform:scale(1.25);
    }
    70% {
      transform:scale(1);
    }
  }
  
`

export default GlobalStyle
